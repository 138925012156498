<template>
  <div class="partDetail">
    <nav-bar title="零件详情"></nav-bar>
    <van-tabs
      v-model="active"
      @click="tabClick"
      color="linear-gradient(to right, #FD5656 , #FE703F);"
    >
      <van-tab title="基本信息">
        <div class="commonWrapper">
          <div class="flex info">
            <div class="left">品牌名称：</div>
            <div class="right">{{ baseInfo.brandName }}</div>
          </div>
          <div class="flex info">
            <div class="left">配件名称：</div>
            <div class="right">{{ baseInfo.goodsName }}</div>
          </div>
          <div class="flex info">
            <div class="left">出厂编码：</div>
            <div class="right">{{ baseInfo.serialNumber }}</div>
          </div>
          <div class="flex info">
            <div class="left">规格型号：</div>
            <div class="right">{{ baseInfo.goodsStyle }}</div>
          </div>
          <div class="flex info">
            <div class="left">OE号：</div>
            <div class="right">{{ baseInfo.oe }}</div>
          </div>
          <div class="flex info">
            <div class="left">计量单位：</div>
            <div class="right">{{ baseInfo.goodsUnit }}</div>
          </div>
          <div class="flex info">
            <div class="left">4S店参考价格：</div>
            <div class="right">
              <span v-if="baseInfo.price"
                >¥{{ Number(baseInfo.price).toFixed(2) }}</span
              >
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="适用车型">
        <div class="commonWrapper" v-if="0 in carList">
          <van-collapse
            v-model="carActiveNames"
            style="border-radius: 5px; margin-bottom: 10px"
          >
            <van-collapse-item
              v-for="(item, index) in carList"
              :title="item.brandName || ''"
              :key="index"
              :name="index"
            >
              <div v-for="(list, i) in item.serviceList" :key="i">
                <div
                  class="flex"
                  style="justify-content: unset; margin-bottom: 10px"
                >
                  <img src="@/assets/triangle.png" alt="" style="width: 19px" />
                  <div style="color: #464646">{{ list.serviceName }}</div>
                </div>
                <div
                  v-for="(val, key) in list.modelList"
                  :key="key"
                  style="
                    margin-left: 20px;
                    font-size: 12px;
                    margin-bottom: 10px;
                  "
                >
                  {{ val.modelName }}
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
        <div v-else class="empty">
          <img :src="emptyImg" alt="" />
          <div>暂无适用车型</div>
        </div>
      </van-tab>
      <van-tab title="通用OE">
        <div v-if="0 in commomPart" class="commonWrapper">
          <van-collapse v-model="oeActiveNames">
            <van-collapse-item
              v-for="(item, index) in commomPart"
              :key="index"
              :title="item.brandName"
              :name="index"
            >
              <span style="margin-left: 15px">{{ item.oe }}</span>
            </van-collapse-item>
          </van-collapse>
        </div>
        <div v-else class="empty">
          <img :src="emptyImg" alt="" />
          <div>暂无通用OE</div>
        </div>
      </van-tab>
      <van-tab title="替换件">
        <div v-if="0 in oeReplace" class="commonWrapper">
          <div class="replace" v-for="(item, index) in oeReplace" :key="index">
            <div class="name van-hairline--bottom">{{ item.brandName }}</div>
            <div
              class="item van-hairline--bottom"
              v-for="(val, key) in item.brandList"
              :key="key"
            >
              <div class="flex">
                <div>配件品牌：</div>
                <div>{{ val.brandName }}</div>
              </div>
              <div class="flex">
                <div>配件名称：</div>
                <div>{{ val.goodsName }}</div>
              </div>
              <div class="flex">
                <div>OE号：</div>
                <div>{{ val.oe }}</div>
              </div>
              <div class="flex">
                <div>参考价格：</div>
                <div style="color: #fe703f"> <span v-if="val.price">￥{{ val.price}}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="empty">
          <img :src="emptyImg" alt="" />
          <div>暂无替换件</div>
        </div>
      </van-tab>
      <van-tab title="供应商">
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
          v-show="list.length > 0"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="commonWrapper">
              <div class="supplyer" v-for="(item, index) in list" :key="index">
                <div class="params flex">
                  <div>名&nbsp;&nbsp;&nbsp;称：</div>
                  <div>{{ item.name }}</div>
                </div>
                <div class="params flex">
                  <div>零件OE号：</div>
                  <div>{{ item.oe }}</div>
                </div>
                <div class="params flex">
                  <div>会员价格：</div>
                  <div style="color: #fe703f">
                    <span v-show="item.memberPrice"
                      >￥{{ item.memberPrice }}</span
                    >
                  </div>
                </div>
                <div class="params flex">
                  <div>供 应 商：</div>
                  <div>{{ item.vPartyShortName }}</div>
                </div>
                <div class="buy van-hairline--top flex">
                  <div></div>
                  <div class="buyBtn" @click="goBuy(item)">购买</div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
        <div v-show="list.length <= 0" class="empty">
          <img :src="emptyImg" alt="" />
          <div>暂无供应商</div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import emptyImg from "@/assets/empty.png";
import {
  getGoodsInfoById,
  getCarModelInfoByGoodsId,
  getGeneralOes,
  getOeReplace,
  listGoods,
  goBuy,
} from "@/api";
import {
  Tab,
  Tabs,
  Sticky,
  Collapse,
  CollapseItem,
  Button,
  List,
  PullRefresh,
  Toast,
} from "vant";
export default {
  name: "partDetail",
  components: {
    NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Sticky.name]: Sticky,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Button.name]: Button,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      emptyImg,
      active: 0,
      carActiveNames: [],
      oeActiveNames: [],
      activeNames: [],
      userId: "",
      goodsId: "", //商品ID
      baseInfo: {}, //基本信息
      carList: [], //使用车型
      commomPart: [], //通用OE
      oeReplace: [], //替换件
      fromUrl: "", //入口地址
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      current: 1,
      size: 5,
    };
  },
  methods: {
    //购买
    goBuy(item) {
      var { userId, fromUrl } = this;
      goBuy({ userId, goodsId: item.id, goodsNum: 1, fromUrl }).then((res) => {
        console.log(res);
        if (res.data.data.jumpUrl) {
          window.location.href = res.data.data.jumpUrl;
        } else {
          Toast.fail("跳转失败,请稍后重试");
        }
      });
    },
    //基本信息
    getGoodsInfoById() {
      var { userId, goodsId } = this;
      console.log(goodsId)
      getGoodsInfoById({ userId, goodsId }).then((res) => {
        console.log(res);
        this.baseInfo = res.data.data;
      });
    },
    //适用车型
    getCarModelInfoByGoodsId() {
      var { userId, goodsId } = this;
      getCarModelInfoByGoodsId({ userId, goodsId }).then((res) => {
        console.log(res);
        this.carList = res.data.data.carList;
      });
    },
    //通用OE
    getGeneralOes() {
      var { userId, goodsId } = this;
      getGeneralOes({ userId, goodsId }).then((res) => {
        if (res.data.data.carList) {
          this.commomPart = res.data.data.carList;
        }
      });
    },
    //替换件
    getOeReplace() {
      var { userId, goodsId } = this;
      getOeReplace({ userId, goodsId }).then((res) => {
        console.log(res);
        if (res.data.data.oeReplaceList) {
          this.oeReplace = res.data.data.oeReplaceList;
        }
      });
    },
    //供应商
    onLoad() {
      var { userId, current, size } = this;
      var { oe, goodsId, goodsStyle, serialNumber } = this.baseInfo;
      var obj = {
        userId,
        baseGoodsId: goodsId,
        oem: oe,
        goodsStyle,
        serialNumberStr: serialNumber,
        currentPageNo: 1,
        pageSize: size * current,
      }; //最后取这个
      this.finished = false;
      this.loading = true;
      listGoods(obj)
        .then((res) => {
          console.log(res);
          if (res && res.data.status.code == 0) {
            var totalPages = (res.data.data && res.data.data.totalPages) || 0;
            var list = (res.data.data && res.data.data.list) || [];
            if (list.length == 0) {
              this.finished = true;
            }
            this.list = [...list];
            this.loading = false;
            this.current = this.current + 1;
            this.refreshing = false;
            this.finished = false;
            if (totalPages <= 1) {
              this.finished = true;
            }
          } else {
            this.finished = true;
          }
        })
        .catch((err) => {
          this.finished = true;
          this.refreshing = false;
          this.loading = false;
        });
    },
    //下拉刷新
    onRefresh() {
      this.list = [];
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    //tab切换点击
    tabClick(name) {
      switch (name) {
        case 0:
          this.getGoodsInfoById();
          break;
        case 1:
          this.getCarModelInfoByGoodsId();
          break;
        case 2:
          this.getGeneralOes();
          break;
        case 3:
          this.getOeReplace();
          break;
        case 4:
          this.onLoad();
          break;
        default:
          break;
      }
    },
  },
  created() {
    
    this.userId = sessionStorage.getItem("userId");
    this.fromUrl = sessionStorage.getItem("fromUrl");
    console.log(this.$route.params.goodsId)
    this.goodsId = this.$route.params.goodsId;
    console.log(this.goodsId)
    this.getGoodsInfoById();
  },
};
</script>

<style lang="less" scoped>
.partDetail {
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 80px;
      margin: 60px 0 30px;
    }
  }
  .commonWrapper {
    background: #f8f8f8;
    padding: 10px 15px;
  }
  padding-top: 50px;
  height: 100%;
  background: #f8f8f8;
  .supplyer {
    background: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    .params {
      line-height: 2.5;
      div {
        &:first-child {
          color: #767676;
        }
        &:last-child {
          color: #464646;
        }
      }
    }
    .buy {
      height: 57px;
      line-height: 57px;
      .buyBtn {
        width: 94px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 15px;
        // background: linear-gradient(to right, #fd5656, #fe703f);
        background: linear-gradient(to right, #1677ff, #2f99ff);
        color: #fff;
      }
    }
  }
  .replace {
    background: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    .name {
      padding: 0 15px;
      height: 35px;
      line-height: 35px;
      font-weight: bold;
      font-size: 16px;
    }
    .item {
      .flex {
        margin: 8px 0;
        padding: 5px 15px;
        div {
          &:first-child {
            color: #767676;
          }
          &:last-child {
            color: #464646;
          }
        }
      }
    }
  }
  .info {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    .left {
      color: #464646;
      width: 110px;
    }
    .right {
      color: #4a4a4a;
      flex: 1;
      word-break: break-word;
      text-align: right;
    }
  }
}
</style>